#keyboard {
  height: var(--keyboard-height);
  padding: 0 5px;
  border-bottom: 2px solid #FFFFFF00;
  box-sizing: border-box;
}

.keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  height: calc((100% - 24px) / 3);
}

.key {
  font-family: inherit;
  font-weight: bold;
  border: 0;
  padding: 0;
  height: 100%;
  width: 10%;
  margin: 0 3px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: var(--key-bg);
  color: var(--key-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0,0,0,0.3);
}

.absent-key {
  background-color: var(--color-absent);
  color: var(--key-evaluated-text-color);
}

.somewhere-key {
  background-color: var(--color-present);
  color: var(--key-evaluated-text-color);
}

.correct-key {
  background-color: var(--color-correct);
  color: var(--key-evaluated-text-color);
}

.extra-wide-key {
  width: 15%;
}

.spacer {
  width: 5%;
}