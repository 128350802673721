@import "./overlays/settings.css";

#overlay {
  z-index: 200;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--opacity-50);
}

#overlay-inner {
  width: var(--game-max-width);
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: var(--color-background);
  border-radius: 4px;
  border: 1px solid var(--color-tone-4);
  padding: 4px;
}

#overlay-content {
  color: var(--color-tone-1);
  padding: 4px;
}

@keyframes SlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes SlideOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}