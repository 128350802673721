@import "./colors.css";
@import "./animations.css";

@import "./components/board.css";
@import "./components/keyboard.css";
@import "./components/overlay.css";
@import "./components/toast.css";

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: var(--color-background);
  margin: 0;
  padding: 0;
  /* Prevent scrollbar appearing on page transition */
  overflow-y: hidden;
}

.hidden {
  display: none!important;
}

#game {
  max-width: var(--game-max-width);
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  color: var(--color-tone-1);
  border-bottom: 1px solid var(--color-tone-4);
  width: 100%;
}

.title {
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}

.icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: var(--color-tone-3);
}