
.slide-in {
  animation: SlideIn 100ms linear;
}

@keyframes SlideIn {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.slide-out {
  animation: SlideOut 150ms linear;
}

@keyframes SlideOut {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(60px);
  }
}

.invalid {
  animation-name: Shake;
  animation-duration: 600ms;
}

/* Used on invalid guess */
@keyframes Shake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }

  40%,
  60% {
    transform: translateX(4px);
  }
}

.win {
  animation-name: Bounce;
  animation-duration: 1s;
}

/* Used on correct guess */
@keyframes Bounce {
  0%, 20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(-15px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}

.pending {
  animation-name: PopIn;
  animation-duration: 100ms;
}

/* Used on tile input */
@keyframes PopIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  40% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.flip-out {
  animation-name: FlipOut;
  animation-duration: 250ms;
  animation-timing-function: ease-in;
}

/* Used on tile reveal */
@keyframes FlipOut {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(-90deg);
  }
}

.flip-in {
  animation-name: FlipIn;
  animation-duration: 250ms;
  animation-timing-function: ease-in;
}

/* Used on tile reveal */
@keyframes FlipIn {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.fade-out {
  animation-name: FadeOut;
  animation-duration: 100ms;
  animation-timing-function: linear;
}

@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}