.option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  padding-top: 8px;
}

.option-label {
  font-size: 18px;
}

.option-desc {
  font-size: 12px;
  color: var(--color-tone-2);
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 9999px;
  background-color: var(--color-tone-3);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  border-radius: 50%;
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: var(--white);
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--color-correct);
}

input:checked + .slider:before {
  transform: translateX(12px);
}

select {
  border-radius: 4px;
  border: 2px solid var(--color-tone-4);
  outline: none;
  background: none;
  padding: 2px;
  color: var(--color-tone-1);
}

option {
  background-color: var(--color-background);
  outline: none;
}

option:focus {
  background-color: var(--color-correct);
}